/* eslint-disable */
// export const appHomeUrl = "http://localhost:5000";
//offshore
// export const appHomeUrl = "http://dev.knotify.kyybaapps.com/";
// export const appHomeUrl = "http://44.217.223.213:3800";

export const appHomeUrl = "https://devknotify.kyybaapps.com/";

//onshore
// export const appHomeUrl = "https://dta-falcon-prod.ehs.mass.gov";


export const msalConfig = {
    auth: {
        //onsite
        // clientId: "e2281eb3-4349-4386-a499-91ff8ba91fb8",
        //offshore
        clientId: "44327953-b778-4885-b8bc-e94aa9014baa",
        authority: "https://login.microsoftonline.com/3e861d16-48b7-4a0e-9806-8c04d81b7b2a",
        knownAuthorities: ["massgov.onmicrosoft.com"],
        redirectUri: appHomeUrl,
        postLogoutRedirectUri: appHomeUrl,
        // response_type: "id_token",
        // prompt: "login",
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['openid', 'profile', 'user.read', 'email'],
    extraQueryParameters: {},
    authority: "https://login.microsoftonline.com/3e861d16-48b7-4a0e-9806-8c04d81b7b2a",
};


export const postLogoutRedirectUri = {
redirectUri: `${appHomeUrl}/login`
}

export const config = {
    //onshore
    // 'API_URL' : "https://dta-falconapi-prod.ehs.mass.gov/falcon",
    //offshore
 //   'API_URL' : "https://apif.kyybadev.com/api",
//    'API_URL' : "http://3.223.210.109:8080/api",

    'API_URL' : "https://api.devknotify.kyybaapps.com/api",

    'CAMPAIGN_STATUS': [{key:'All', value:0},{key:'Processed',value:206}, {key:'Scheduled',value:203}, {key:'Processing',value:202},{key:'Cancelled',value:205}],
    'CHANNEL_LIST': [
        {key: "All Communication Channels", value: "all"},
        {key: "SMS", value: "sms"},
        {key: "Email", value: "email"},
        // {key: "Voice", value: "voice"},
    ]
}

export const origin= "*"

export const roles = {
    "NMSBSR": "Business User",
    "NMSADN": "Agency Admin"
  }