import * as actionTypes from '../actions/actionTypes';

const initialState = {
  templates: {
    templates: [],
    count: "",
    isLoading: false,
    isError: false
  },
  createTemplateData: {},
  isLoading: false,
  isError: false,
  editTemplate: {},
  editTemplateData: {},
  deleteTemplateData: {
    isLoading: false,
    isError: false,
    data: {}
  },
  campaignsLov: {
    isLoading: false,
    isError: false,
    data: []
  }
}

const templateReducer = (state = initialState, action = {}) => {

  const newState = { ...state };

  switch (action.type) {
    case actionTypes.FETCH_TEMPLATE_LIST:
      return { ...newState, templates: { ...newState.templates, isLoading: true } };

    case actionTypes.FETCH_TEMPLATE_LIST_SUCCESS:
      return { ...newState, templates: { templates: [...action.payload.data], count: action.payload.totalCount, isLoading: false, isError: false } };

    case actionTypes.FETCH_TEMPLATE_LIST_FAILURE:
      return { isLoading: false, isError: true };

    case actionTypes.CREATE_TEMPLATE:
      return { ...newState, isLoading: true, createTemplateData: {} };

    case actionTypes.CREATE_TEMPLATE_SUCCESS:
      return { ...newState, isLoading: false, createTemplateData: { ...action.payload } };

    case actionTypes.CREATE_TEMPLATE_FAILURE:
      return { ...newState, isLoading: false, isError: true, createTemplateData: { ...action.payload } };

    case actionTypes.SET_EDIT_TEMPLATE_DATA:
      return { ...newState, isLoading: false, isError: false, editTemplate: { ...action.payload }, editTemplateData: {} };

    case actionTypes.EDIT_TEMPLATE:
      return { ...newState, isLoading: true };

    case actionTypes.EDIT_TEMPLATE_SUCCESS:
      return { ...newState, isLoading: false, editTemplateData: { ...action.payload } };

    case actionTypes.EDIT_TEMPLATE_FAILURE:
      return { ...newState, isLoading: false, isError: true, editTemplateData: { ...action.payload } };

    case actionTypes.DELETE_TEMPLATE:
      return { ...newState, deleteTemplateData: { isLoading: true } };

    case actionTypes.DELETE_TEMPLATE_SUCCESS:
      return { ...newState, deleteTemplateData: { isLoading: false, data: { ...action.payload } } };

    case actionTypes.DELETE_TEMPLATE_FAILURE:
      return { ...newState, deleteTemplateData: { isLoading: false, data: { ...action.payload } } };

    case actionTypes.FETCH_CAMPAIGNS_LOV:
      return { ...newState, campaignsLov: { isLoading: true,data:[] } };

    case actionTypes.FETCH_CAMPAIGNS_LOV_SUCCESS:
      return { ...newState, campaignsLov: { isLoading: false, data: action.payload.data  }  };

    case actionTypes.FETCH_CAMPAIGNS_LOV_FAILURE:
      return { ...newState, campaignsLov: { isLoading: false, data: { ...action.payload } } };

    default:
      return { ...newState };
  }
};


export default templateReducer;