import * as auditConstants from './actionTypes';
import {config} from '../../config/config';
import request from '../../components/Helper/Request';

const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));

/*eslint-disable */
export function fetchAuditList(data, search, limit, page) {

    return (dispatch) => {
      dispatch({
        type: auditConstants.FETCH_AUDITS,
      });
  
  
      request({
        method: 'get',
        url: `${config.API_URL}/api/audit/list/${userDetails?.accountId}/${userDetails?.userId}`,
        params: {
          filterBy: data.filterBy,
          startDate: data.startDate,
          endDate: data.endDate,
          search,
          limit,
          page
        }
      }).then((response) => {
  
        dispatch({
          type: auditConstants.FETCH_AUDITS_SUCCESS,
          payload: response.data
        });
  
      })
        .catch((error) => {
          dispatch({
            type: auditConstants.FETCH_AUDITS_FAILURE,
            payload: error
          });
        })
    }
  }
  
  