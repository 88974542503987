import React from 'react';
import styled from 'styled-components'
import PropTypes from 'prop-types';


const Title = styled.h2`
    font-size:16px; 
    color: ${props => (props.textColor ? props.textColor : "#001E3E")};
`;
const Value = styled.h3`
  font-weight: bold;
  color: ${props => (props.textColor ? props.textColor : "#001E3E")};
`;

const String = styled.h4`
  font-weight: bold;
  color: ${props => (props.textColor ? props.textColor : "#001E3E")};
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.div`
    display: inline-block;
  background: #fff;
  width: ${props => (props.width ? props.width : '31.3%')};
  height: ${props => (props.height ? props.height : 'auto')};
  text-align:center;
  margin: 5px 10px 5px 0;
  border: ${props => (props.border ? props.border : '2px solid #ddd')};
  border-radius:${props => (props.borderShape ? '5px' : '100px')};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; `;



const KPICard = ({ label, value=0, type, borderShape = true, border = '', textColor="", children="", width="", height="", padding="" }) => {

  return <Wrapper borderShape={borderShape} border={border} width={width} height={height} padding={padding}>
    <Title textColor={textColor}>
      {label}
    </Title>
    {type === "number" ? <Value textColor={textColor}>
      {value.toLocaleString("en-US")}
    </Value> : <String textColor={textColor}>
      {value}
    </String>
    }
    { children}
  </Wrapper>
}

KPICard.defaultProps = {
  borderShape: true,
  border: '',
  textColor: '',
  children:null,
  height:'',
  width:'',
  padding:''
}



KPICard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  borderShape: PropTypes.bool,
  border: PropTypes.string,
  textColor: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string
}

export default KPICard;
