/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    FormControlLabel,
    Typography,
    Grid,
    TextField,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import Swal from "sweetalert2";
import * as templateActions from '../store/actions/template';
import * as settingsActions from '../store/actions/settings';
import * as Styled from '../components/styled-components/Campaign';
import RadioButtonGroup from '../components/RadioButtonGroup';
import EmailContentCreation from '../components/EmailContentCreation';
import SpinningLoader from '../components/SpinningLoader';
import appConfig from '../config/appConfig';
import { retriveLanguage } from '../components/Utils';


const ErrorStyle = {
    color: "red",
    display: 'flex',
    marginTop: '5px'
}



const textboxCss = {
    border: `1px solid ${appConfig.colors.darkblue}`,
    borderRadius: "4px",
    outline: "none",
    '&.MuiFormControl-root': {
        margin: "0"
    },
    '&.MuiTextField-root': {
        backgroundColor: '#fff'
    },
    '& .MuiInputBase-input': {
        backgroundColor: '#fff',
        fontSize: 16,
        fontFamily: appConfig.fontfamily,
        padding: "10px 14px",
        borderRadius: "4px",
        outline: "none",

    },
    '& .MuiOutlinedInput-root': {
        padding: '0',
    }
};

const CreateTemplate = ({ editTemplate, templateData, settingsData ,fetchEmailConfiguration}) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isContentEnabled, setIsContentEnabled] = useState(true);
    const [channel, setChannel] = useState(templateData?.editTemplate?.channel?.charAt(0).toUpperCase() + templateData?.editTemplate?.channel?.slice(1) || "SMS");
    const [selectedLanguage, setSelectedLanguage] = useState([
        {
            lan: 'en',
            name: 'English (en)',
            isEnabled: false,
            text: '',
            subject: '',
            content: '',
            attachment: ''
        },
        {
            lan: 'pt',
            name: 'Portuguese (pt)',
            isEnabled: false,
            text: '',
            subject: '',
            content: '',
            attachment: ''
        }, {
            lan: 'vi',
            name: 'Vietnamese (vi)',
            isEnabled: false,
            text: '',
            subject: '',
            content: '',
            attachment: ''
        }, {
            lan: 'es',
            name: 'Spanish (es)',
            isEnabled: false,
            text: '',
            subject: '',
            content: '',
            attachment: ''
        },
        {
            lan: 'zh',
            name: 'Chinese (zh)',
            isEnabled: false,
            text: '',
            subject: '',
            content: '',
            attachment: ''
        },
        {
            lan: 'ht',
            name: 'Haitian Creole (ht)',
            isEnabled: false,
            text: '',
            subject: '',
            content: '',
            attachment: ''
        }
    ]
    );
    const [isError, setError] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [formValue, setFormValue] = useState(null);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const updateIsEnabledChanged = (index, value) => {
        selectedLanguage.forEach((l, i) => {
            if (channel === "Email" && i === index && value) {
                if (!Object.keys(settingsData?.templateEmailSettings).includes(l.lan)) {
                    fetchEmailConfiguration(l.lan);
                }
            }
        })
        setError(false);
        setIsEmpty(false);
        const data = [...selectedLanguage]; // copying the old datas array
        data[index].isEnabled = value;
        if (!data[index].isEnabled) {
            data[index].text = ''
            data[index].subject = ''
            data[index].content = ''
            data[index].attachment = ''
        }
        setSelectedLanguage(data);
    }


    useEffect(() => {
        let lang = [];
        lang = templateData?.editTemplate?.templateDetails?.map((item) => {
            return {
                lan: item.language,
                name: retriveLanguage(item.language),
                isEnabled: true,
                text: item?.text,
                subject: item?.subject,
                content: item?.content,
                attachment: item?.attachment,
                id: item.id,
                createdTime: item?.createdTime
            }
        })

        const contents = new Set(lang?.map(d => d.lan));
        if (lang) {
            const merged = [...lang, ...selectedLanguage.filter(d => !contents.has(d.lan))];
            setSelectedLanguage(merged);
        }

    }, [])

    useEffect(() => {
        setFormValue(null);
        if (isContentEnabled) {
            selectedLanguage.forEach((item) => {
                if (item.isEnabled) {
                    let contentText = {
                        content: channel.toLowerCase() === 'email' || channel.toLowerCase() === 'sms' ? item?.content : item.text,
                        language: item.lan
                    };
                    { contentText.id = item.id || "" }
                    { contentText.createdTime = item.createdTime || "" }
                    if (channel.toLowerCase() === 'email') {
                        contentText = {
                            subject: item.subject ? item.subject : '',
                            attachment: item.attachment ? item.attachment : '',
                            content: item.content ? item.content : '',
                            language: item.lan
                        }
                        { contentText.id = item.id || "" }
                        { contentText.createdTime = item.createdTime || "" }
                    } else if (channel.toLowerCase() === 'sms') {
                        contentText = {
                            attachment: item.attachment ? item.attachment : '',
                            content: item.content ? item.content : '',
                            language: item.lan
                        }
                        { contentText.id = item.id || "" }
                        { contentText.createdTime = item.createdTime || "" }
                    }

                    setFormValue(prevState => ({
                        ...prevState,
                        channel,
                        isContentEnabled,
                        contentJson: {
                            ...prevState?.contentJson,
                            [item.lan]: contentText
                        },
                        languageEnabled: {
                            ...prevState?.languageEnabled,
                            [item.lan]: item.isEnabled
                        },

                    }));
                }
                return formValue;
            })
        } else {
            setFormValue(prevState => ({
                ...prevState,
                channel,
                isContentEnabled,
                contentJson: {},
                languageEnabled: {
                }
            }));
        }
    }, [isContentEnabled, channel, selectedLanguage]);

    useEffect(() => {
        if (isError) {
            setIsEmpty(false);
        }
        if (isEmpty) {
            setError(false);
        }
    }, [isEmpty, isError]);

    
    useEffect(() => {
        // resetTemplateConfiguration()
        selectedLanguage.forEach((la)=>{
            if (!Object.keys(settingsData?.templateEmailSettings).includes(la.lan)) {
                fetchEmailConfiguration(la.lan);
            }
        })
    }, [])

    const emailSubjectChange = (e, index) => {

        const data = [...selectedLanguage];
        data[index].subject = e.target.value;
        setSelectedLanguage(data);
    }
    const emailContentChange = (e, index) => {
        if (channel.toLowerCase() === "sms" && e.target.value === "") {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }

        if (channel.toLowerCase() === "email" && e.length <= 8) {
            setIsEmpty(true)
        } else {
            setIsEmpty(false)
        }
        const data = [...selectedLanguage];
        if (channel.toLowerCase() === "email") {
            data[index].content = e;
        } else {
            data[index].content = e.target.value;
        }
        setSelectedLanguage(data);
    }

    const sendFormValues = () => {
        if (formValue === null) {
            setError(true);
            setIsEmpty(true);
        } else {
            const empty = Object.values(formValue?.contentJson).some(item => {
                if (channel.toLowerCase() === "email") {
                    if (!item?.subject || !item?.content) {
                        return true
                    }
                } else {
                    if (!item?.content) {
                        return true
                    }
                }
            });
            if (empty) {
                setIsEmpty(true);
                setError(true);
            }
        }
    }

    const onSubmit = (data) => {
        let formEmptyError = true;
        let editTemplateData;
        if (formValue?.contentJson) {
            editTemplateData = {
                templateDetails: Object.entries(formValue?.contentJson).map((e) => {
                    e[1].templateId = templateData.editTemplate.id;
                    if (channel.toLowerCase() === "email" && e[1].content.length <= 8) {
                        formEmptyError = true;
                        setIsEmpty(true);
                    } else if (channel.toLowerCase() === "sms" && e[1].content.length === 0) {
                        formEmptyError = true;
                        setIsEmpty(true);
                    } else {
                        formEmptyError = false;
                        setIsEmpty(false);
                    }
                    return e[1]
                }),
                id: templateData.editTemplate.id,
                name: data.name ? data.name : templateData.editTemplate.name,
                code: templateData.editTemplate.code,
                channel: templateData.editTemplate.channel,
                campaignId: templateData.editTemplate.campaignDetails.id
            };
        } else {
            setError(true)
        }
        if (formEmptyError) {
            setIsEmpty(true);
        } else {
            setIsFormSubmit(true);
            setIsLoading(true);
            editTemplate(editTemplateData);
        }
    }

    const formatTemplate = (oneItem) => {
        if (channel.toLowerCase() === 'email') {
            const contentBlock = htmlToDraft(oneItem.content.replace(/<[>]+>/g, ''));
            if (contentBlock) {
                const content = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editor = EditorState.createWithContent(content);
                const data = { ...oneItem, content: editor }
                return data;
            }
        } else {
            return oneItem;
        }
    }

    useEffect(() => {
        if (templateData?.editTemplateData?.status === 1 && isFormSubmit) {
            setIsFormSubmit(false);
            setIsLoading(false);
            Swal.fire({
                icon: templateData?.editTemplateData?.status === 1 ? "success" : "warning",
                button: false,
                showConfirmButton: false,
                timer: 2000,
                text: templateData?.editTemplateData?.message,
            });
            setTimeout(() => {
                navigate("/templates");
            }, 3000);
        } else if (isFormSubmit && templateData?.editTemplateData?.status === 0 || templateData?.isError) {
            setIsFormSubmit(false);
            setIsLoading(false);
            Swal.fire({
                icon: "warning",
                button: false,
                showConfirmButton: false,
                timer: 2000,
                text: "Please try again, later",
            });
            setTimeout(() => {
                navigate("/templates");
            }, 3000);

        }
    }, [JSON.stringify(templateData?.editTemplateData.status)])


    return (
        <div>
            {isLoading ? <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <SpinningLoader circleColor={appConfig.colors.darkblue} label="loading" />
            </Backdrop> : null}
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
                    <h1 className='page-heading' ><b>Edit Template</b></h1>
                </Grid>
                <Grid item xs={6} xl={6} lg={6} md={6}>
                    <div className='text-align-right'>
                        <Styled.CampaignButton
                            className='text-trans-none'
                            variant="contained"
                            onClick={() => navigate('/templates')}
                        >
                            Templates
                        </Styled.CampaignButton>
                    </div>
                </Grid>
            </Grid>
            <hr style={{ border: "3px solid #EDF6F5", margin: "20px 0" }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item md={7} xl={7} lg={7} sm={12} xs={12}>
                        <div >
                            <Typography component="p" sx={{ mb: 1 }}>Template Name (Required)</Typography>
                            <TextField
                                {...register('name')}
                                margin="normal"
                                defaultValue={templateData?.editTemplate?.name || ""}
                                id="name"
                                sx={textboxCss}
                                fullWidth
                                disabled
                            />
                        </div>
                        {errors.name ? <span style={ErrorStyle}>Please enter template name.</span> : null}
                    </Grid>

                    <Grid item md={7} xl={7} lg={7} sm={12} xs={12}>
                        <div >
                            <Typography component="p" sx={{ mb: 1 }}>Template Code (Required)</Typography>
                            <TextField
                                {...register('code')}
                                margin="normal"
                                defaultValue={templateData?.editTemplate?.code || ""}
                                id="code"
                                sx={textboxCss}
                                disabled
                                fullWidth
                            />
                        </div>
                        {errors.code ? <span style={ErrorStyle}>Please enter template code.</span> : null}
                    </Grid>
                    <Grid item xl={7} md={7} lg={7} sm={12} xs={12}>
                        <Typography component="p" sx={{ mt: 1, mb: 2 }}>Communication Channel</Typography>
                        <RadioButtonGroup
                            // key={channel}
                            className={['create-using-account-radio', templateData?.editTemplate?.channel].join(' ')}
                            value={templateData?.editTemplate?.channel}
                            labels={['SMS', 'Email']}
                            disabled
                        />
                    </Grid>

                    <Grid
                        item
                        md={7} xl={7} lg={7} sm={12} xs={12}
                    >

                        <Typography component="p" sx={{ mb: 1 }}>Associated Campaign</Typography>
                        <TextField
                            margin="normal"
                            defaultValue={templateData?.editTemplate?.campaignDetails?.campaignName || ""}
                            id="campaign name"
                            sx={textboxCss}
                            disabled
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={8} xl={8} lg={8} sm={12} xs={12}>
                        <Typography component="p" style={{ marginBottom: '10px' }}>Choose Language</Typography>
                        {selectedLanguage.map((item, index) => {
                            return <FormControlLabel
                                key={item.lan}
                                sx={item.isEnabled ? appConfig.checkboxStyleChecked : appConfig.checkboxStyle}
                                control={
                                    <Checkbox
                                        {...register('language')}
                                        sx={item.isEnabled ? { color: appConfig.colors.white } : { color: appConfig.colors.darkblue }}
                                        key={item.lan}
                                        checked={item.isEnabled}
                                        disabled={!isContentEnabled}
                                        onChange={(e) => {
                                            updateIsEnabledChanged(index, e.target.checked);
                                        }}
                                    />
                                }
                                label={retriveLanguage(item.lan)}
                            />
                        })}
                        {isError ? <span style={ErrorStyle}>Please choose a language.</span> : null}
                        {isEmpty ? <span style={ErrorStyle}>Content shoud not be empty for selected language.</span> : null}
                        <br />
                        <br />
                        <Grid item md={12} lg={12} xl={12} sm={12} xs={12}>
                            {selectedLanguage.map((item, index) => {
                                const templData = formatTemplate(item)
                                return item.isEnabled && (
                                    <>

                                        <Grid item md={11} xl={11} lg={11} xs={12} sm={12} >
                                            <EmailContentCreation
                                                isEnableSubject={templateData?.editTemplate?.channel.toLowerCase() === 'email' ? true : false}
                                                fileFormat={templateData?.editTemplate?.channel.toLowerCase() === 'sms' ? [".jpeg", ".jpg", ".png", ".pdf"] : "*"}
                                                emailContent={templData}
                                                disabled={!isContentEnabled}
                                                // handleFiles={handleFiles}
                                                emailSubjectChange={emailSubjectChange}
                                                emailContentChange={emailContentChange}
                                                index={index}
                                                isAttachment={false}
                                                previewChildren={
                                                    <div>
                                                    <p dangerouslySetInnerHTML={{ __html: settingsData.templateEmailSettings[item.lan]?.header }} />
                                                    <p dangerouslySetInnerHTML={{ __html: item.content === "" ? "<h3><b>< Enter your template content here ></b></h3>" : item.content }} />
                                                    <p dangerouslySetInnerHTML={{ __html: settingsData.templateEmailSettings[item.lan]?.footer }} />
                                                </div>
                                                }
                                                />
                                        </Grid>
                                    </>

                                )
                            })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
                        <hr className='blue-border' />
                    </Grid>
                    <Grid container spacing={1} sx={{ marginLeft: "10px" }}>
                        <Grid item xs={12} sm={4} xl={1.5} lg={1.5} md={1.5}>
                            <Styled.CancelButton
                                variant="outlined"
                                sx={{ mt: 3, fontFamily: appConfig.fontfamily, textTransform: 'capitalize', width: '100%', height: "50px" }}
                                onClick={() => navigate('/templates')}
                            >
                                Cancel
                            </Styled.CancelButton>
                        </Grid>
                        <Grid item xs={12} sm={8} xl={5.6} lg={5.6} md={5.6}>
                            <Styled.CampaignButton
                                type='submit'
                                onClick={() => sendFormValues()}
                                variant="contained"
                                sx={{ mt: 3, fontFamily: appConfig.fontfamily, textTransform: 'capitalize', width: '100%', height: "50px" }}
                            >
                                Update
                            </Styled.CampaignButton>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

CreateTemplate.propTypes = {
    createTemplate: PropTypes.func.isRequired,
    templateData: PropTypes.symbol.isRequired,
    editTemplate: PropTypes.func.isRequired,
    fetchEmailConfiguration: PropTypes.func.isRequired,
    settingsData: PropTypes.object.isRequired,

}
const mapStateToProps = (state) => ({ templateData: state.template, settingsData: state.settings })

const mapDispatchToProps = (dispatch) => {
    return {
        editTemplate: (fields) => dispatch(templateActions.editTemplate(fields)),
        fetchEmailConfiguration: (lang) => dispatch(settingsActions.fetchEmailConfigurationForTemplate(lang)),
        // resetTemplateConfiguration: ()=>dispatch(settingsActions.resetEmailConfiguration())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplate);