import * as templateConstants from './actionTypes';
import {config} from '../../config/config';
import request from '../../components/Helper/Request';

const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));

export const getTemplateList = (limit,page,searchText,filter) => {
    return (dispatch) => {
      dispatch({
        type: templateConstants.FETCH_TEMPLATE_LIST
      });

      request({
          method: 'get',
          url:`${config.API_URL}/api/templates/${userDetails?.accountId}/${userDetails?.userId}`,   
          params: {
              search: searchText,
              filterBy:filter.templateFilter.value,
              limit,
              page,
              sortOrder : filter.sortValue.sortOrder || "desc",
              sortBy :filter.sortValue.sortBy ||"modifiedTime"
          }
      })
        .then((response) => { 
          dispatch({
            type: templateConstants.FETCH_TEMPLATE_LIST_SUCCESS,
            payload: response.data
          });
        })
        .catch((error) => {
          dispatch({
            type: templateConstants.FETCH_TEMPLATE_LIST_FAILURE,
            payload:error
          });
        })
    }
  
  }

export const createTemplate =(templateData) =>{

    const data = {...templateData,  
      userId: userDetails?.userId,
      accountId: userDetails?.accountId,
      createdBy: userDetails?.emailId,
      modifiedBy: userDetails?.emailId
    }; 
    return (dispatch) => {
    dispatch({
        type: templateConstants.CREATE_TEMPLATE
    });

    request({
        method: 'post',
        url:`${config.API_URL}/api/templates/create/${userDetails?.userId}`,   
        data
    })
    .then((response) => {
        dispatch({
        type: templateConstants.CREATE_TEMPLATE_SUCCESS,
        payload: response
        });
    }).catch((error) => {
        dispatch({
        type: templateConstants.CREATE_TEMPLATE_FAILURE,
        payload:error?.response?.data
        });
    });
    }
}



export const setEditTemplateData = (data) => {

  return (dispatch) => {
    dispatch({
        type: templateConstants.SET_EDIT_TEMPLATE_DATA,
        payload:data
    });
  

  }
}



export const editTemplate =(templateData) =>{
        
  const data = {
    ...templateData,
    userId: userDetails?.userId,
    accountId: userDetails?.accountId,
    modifiedBy: userDetails?.emailId
  }; 

  return (dispatch) => {
  dispatch({
      type: templateConstants.EDIT_TEMPLATE
  });

  request({
      method: 'post',
      url:`${config.API_URL}/api/templates/update/${userDetails?.userId}`,   
      data
  })
  .then((response) => {
      dispatch({
      type: templateConstants.EDIT_TEMPLATE_SUCCESS,
      payload: response
      });
  }).catch((error) => {

      dispatch({
      type: templateConstants.EDIT_TEMPLATE_FAILURE,
      payload:error?.response?.data
      });
  });
  }
}


export const deleteTemplate =(templateData) =>{
        
  const data = {
    modifiedBy: templateData?.modifiedBy
  }; 

  return (dispatch) => {
  dispatch({
      type: templateConstants.DELETE_TEMPLATE
  });

  request({
      method: 'post',
      url:`${config.API_URL}/api/templates/delete/${userDetails?.accountId}/${userDetails?.userId}/${templateData?.id}`,   
      data
  })
  .then((response) => {
      dispatch({
      type: templateConstants.DELETE_TEMPLATE_SUCCESS,
      payload: response
      });
  }).catch((error) => {
      dispatch({
      type: templateConstants.DELETE_TEMPLATE_FAILURE,
      payload:error?.response?.data
      });
  });
  }
}


export const getCampaignsListLov = (channel) => {
  return (dispatch) => {
    dispatch({
      type: templateConstants.FETCH_CAMPAIGNS_LOV
    });

    request({
        method: 'get',
        url:`${config.API_URL}/api/campaign/lov/${userDetails?.accountId}/${userDetails?.userId}?channel=${channel}`,   
    })
      .then((response) => { 
        dispatch({
          type: templateConstants.FETCH_CAMPAIGNS_LOV_SUCCESS,
          payload: response
        });
      })
      .catch((error) => {
        dispatch({
          type: templateConstants.FETCH_CAMPAIGNS_LOV_FAILURE,
          payload:error
        });
      })
  }

}