import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';

const SideMenuItems = [
    {
        name: "Dashboard",
        route: "dashboard",
        icon: DashboardOutlinedIcon,
        isSubMenu:false,
        isBusRoute:true,
        isUserRoute:true,
        isAdminRoute:true,
        subRoutes:['']
    },
    {
        name: "Campaigns",
        route: "campaign",
        subRoutes: ["campaigndetails","createcampaign", 'uploadfile','campaignfiles'],
        icon: CampaignOutlinedIcon,
        isBusRoute:true,
        isUserRoute:true,
        isAdminRoute:true,
    },
    {
        name: "Templates",
        route: "templates",
        icon: BackupTableIcon,
        subRoutes:["createtemplate","edittemplate"],
        isBusRoute:true,
        isUserRoute:true,
        isAdminRoute:true,
    },
    {
        name: "Instant Notifications",
        route: "instant",
        subRoutes: ["staffalerts"],
        icon: CampaignOutlinedIcon,
        isBusRoute:false,
        isUserRoute:false,
        isAdminRoute:true,
    },
    {
        name: "Reports",
        route: "reports",
        icon: QueryStatsIcon,
        isAdminRoute:true,
        isBusRoute:true,
        isUserRoute:false,
        subRoutes:[]
    },
    {
        name: "Error Log",
        route: "errorlog",
        icon: SpeakerNotesOffOutlinedIcon,
        isAdminRoute:true,
        isBusRoute:true,
        isUserRoute:false,
        subRoutes:[]
    },
    {
        name: "Status Reconciliation",
        route: "reconcile",
        icon: CloudSyncOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:false,
        subRoutes:[]
    },
    {
        name: "Opt Out",
        route: "optoutlist",
        icon: PanToolOutlinedIcon,
        isAdminRoute:true,
        isBusRoute:true,
        isUserRoute:false,
        subRoutes:[]
    },
    {
        name: "User Management",
        route: "usermanagement",
        icon: GroupOutlinedIcon,
        isSubMenu:true,
        isAdminRoute:true,
        subRoutes: ["createuser"]
    },
    {
        name: "Email Configuration",
        route: "emailconfiguration",
        icon: SettingsOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:true,
        subRoutes:[
        ]
    },
    {
        name: "Audit Trail",
        route: "audits",
        icon: ScreenSearchDesktopOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:true,
        subRoutes:[]
    },
    {
        name: "Glossary",
        route: "glossary",
        icon: ScreenSearchDesktopOutlinedIcon,
        isSubMenu:false,
        isAdminRoute:true,
        isBusRoute:true,
        isUserRoute:true,
        subRoutes:[]
    }

];

export default SideMenuItems;
