import React from 'react';
import { TextField } from "@mui/material";
import PropTypes from 'prop-types';
import appConfig from '../../config/appConfig';

const InputField = ({ value, onChange, name, disabled = false }) => {
    return <TextField
        value={value}
        fullWidth
        name={name}
        onChange={onChange}
        disabled={disabled}
        id="outlined-basic"
        variant="outlined"
        sx={{
            '& .MuiInputBase-input': {
                backgroundColor: '#fcfcfb',
                fontSize: 16,
                fontFamily: appConfig.fontfamily,
                padding: "10px 14px"
            },
            '& .MuiInputBase-input.Mui-disabled': {
                background: '#e9f2fa'
            }
        }
        }
    />

}

InputField.defaultProps = {
    disabled: false
};

InputField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool

}

export default InputField;