import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import InputField from './Form/InputField';
import TextArea from './Form/TextArea';
import * as Styled from './styled-components/Campaign';
import ModalPopup from './ModalPopup';

const FormStyle = {
    '&.MuiGrid-item': { paddingTop: '0' }
}

const WrapperStyle = {
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: "20px",
}
const EmailContentCreation = ({
    emailContentChange,
    emailSubjectChange,
    emailContent,
    disabled = false,
    isEnableSubject = true,
    index,
    previewChildren,
}) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const editorRef = useRef();
    const [open, setOpen] = useState(false);

    const uploadFileCallback = (file) => {
        return new Promise(
            (resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener('load', () => {
                    resolve({ data: { link: reader.result } });
                });
            }
        );
    }

    const handleEditorChange = (state, i) => {
        try {
            setEditorState(state);
            emailContentChange(`<div>${draftToHtml(convertToRaw((state.getCurrentContent())))}</div>`, i);
        } catch (e) {
            console.log("Editor Error", e)
        }
    }

    const handleClose = () => setOpen(false);


    useEffect(() => {
        setEditorState('');
    }, [isEnableSubject])

    return (
        <div style={WrapperStyle}>
            <Grid
                container
                direction="column"
                spacing={3}
            >
                {isEnableSubject ? (
                    <Grid item sx={FormStyle}>
                        <p >{emailContent.name} Subject</p>
                        <InputField
                            value={emailContent?.subject ? emailContent?.subject : ''}
                            onChange={(e) => { emailSubjectChange(e, index) }}
                            name="subject"
                            disabled={disabled}
                        />
                    </Grid>
                ) : null}

                <Grid container style={{ paddingLeft: "23px", display: "flex", alignItems: "center", marginTop: isEnableSubject ? "10px" : 0 }}>
                    <Grid item xs={6} md={6} lg={6} sm={6} xl={6}>
                        <p className="inline-blk">{emailContent.name} Content (Required)</p>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} sm={6} xl={6}  >
                        {!isEnableSubject && <p className="inline-blk flt-r">Recommended Characters:750</p>}

                        {isEnableSubject && <div className="inline-blk flt-r"><Styled.CampaignButton
                            onClick={() => { setOpen(true) }}
                        >
                            Preview
                        </Styled.CampaignButton> </div>}
                    </Grid>
                    {isEnableSubject ? <div style={{ marginBottom: "15px", userselect: "none" }}><Editor
                        ref={editorRef}
                        editorState={editorState || emailContent.content}
                        handlePastedText={() => false}
                        editorStyle={{ border: "1px solid #ccc", height: "300px", padding: "15px", userselect: "none", maxWidth: "100%" }}
                        toolbarStyle={{ border: "1px solid #ccc", paddingRight: "10%" }}
                        toolbar={
                            {
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'history'],
                                image: { urlEnabled: true, uploadEnabled: false, previewImage: true, inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg', uploadCallback: uploadFileCallback },
                                fontFamily: {
                                    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Source Sans Pro'],
                                },
                            }}
                        onEditorStateChange={(state) => { handleEditorChange(state, index) }}
                    />  </div> :
                        <TextArea
                            value={emailContent?.content ? emailContent?.content : ''}
                            onChange=
                            {(e) => { emailContentChange(e, index) }}
                            name="content"
                            disabled={disabled}
                        />}
                </Grid>
                <Grid item >
                    <ModalPopup
                        open={open}
                        onClose={handleClose}
                    >
                        <div style={{ border: "1px solid #ddd", padding: "10px", marginTop: "20px",height:"500px",overflowY:"auto" }}>
                            <span><b>Email Preview</b></span>
                            {previewChildren}
                        </div>

                    </ModalPopup>
                </Grid>
            </Grid>
        </div >
    );
}
EmailContentCreation.propTypes = {
    emailContentChange: PropTypes.func.isRequired,
    emailSubjectChange: PropTypes.func.isRequired,
    emailContent: PropTypes.objectOf(PropTypes.object).isRequired,
    disabled: PropTypes.bool.isRequired,
    isEnableSubject: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    previewChildren: PropTypes.node.isRequired
}

export default EmailContentCreation;