import * as actionTypes from '../actions/actionTypes';

const initialState = {
  reports: {
    reports: [],
    count: "",
    isLoading: false,
    isError: false
  },
  messageReport : {
    messageReport: [],
    count: "",
    isLoading: false,
    isError: false
  },
  downloadReportList: {
      downloadReportList: "",
      isLoading: false,
      isError: false
  },
  isLoading: false,
  isError: false
}

const reportsReducer = (state = initialState, action = {}) => {

  const newState = { ...state };

  switch (action.type) {
    case actionTypes.FETCH_REPORTS_LIST:
      return { ...newState, reports: { ...newState.reports, isLoading: true }  };

    case actionTypes.FETCH_REPORTS_LIST_SUCCESS:
      return { ...newState, reports: { reports: [...action.payload.data], count: action.payload.totalCount, isLoading: false, isError: false }};

    case actionTypes.FETCH_REPORTS_LIST_FAILURE:
      return { ...newState, reports: { reports: [...action.payload.data], count: action.payload.totalCount, isLoading: false, isError: true }};

    case actionTypes.GET_FAILED_MESSAGE_REPORT:
      return { ...newState, messageReport: { ...newState.messageReport, isLoading: true }};

    case actionTypes.GET_FAILED_MESSAGE_REPORT_SUCCESS:
      return { ...newState, messageReport: { messageReport:[...action.payload.data], count: action.payload.totalCount, isLoading: false,isError: false}};

    case actionTypes.GET_FAILED_MESSAGE_REPORT_FAILURE:
      return { ...newState, messageReport: { messageReport:[...action.payload.data], count: action.payload.totalCount, isLoading: false,isError: true}};

    case actionTypes.DOWNLOAD_FAILED_MESSAGE_REPORT:
        return { ...newState, downloadReportList: { ...newState.downloadReportList, isLoading: true } };

    case actionTypes.DOWNLOAD_FAILED_MESSAGE_REPORT_SUCCESS:
        return { ...newState, downloadReportList: { downloadReportList: action.payload, isLoading: false,isError: false}};

    case actionTypes.DOWNLOAD_FAILED_MESSAGE_REPORT_FAILURE:
        return { ...newState, downloadReportList: { downloadReportList: action.payload, isLoading: false,isError: true}};
  
    default:
      return { ...newState };
  }
};


export default reportsReducer;