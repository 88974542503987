import React, { useState, useEffect,useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns'
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import Swal from "sweetalert2";
// import {  useLocation } from 'react-router-dom';
// import IconButton from '@mui/material/IconButton';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
// import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import SkeletonGridLoader from '../components/SkeletonGridLoader';

import DefaultTable from '../components/Table';
import appConfig from '../config/appConfig';

// import CampaignSnapshot from './CampaignSnapshot';

import * as reconcileActions from '../store/actions/reconcile';
import * as menuActions from '../store/actions/breadcrumb';

import { CalculateUTCTzToUserTz, CalculateUserTzToUTCTz } from '../components/Utils';


const Reconcile = ({ reconcileData, fetchReconcileList, reconcileFile, regenerateFile, setBreadcrumb }) => {

  const [pagination, setPagination] = useState({ limit: 10, offset: 1 });
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfDay(new Date().setDate(new Date().getDate() - 30)),
    endDate: endOfDay(new Date())
  });
  const [channel, setChannel] = useState({ key: "SMS", value: "sms" });
  // const params = useLocation();
  // const snapshotRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [reconcile, setReconcile] = useState({ fileId: "", status: false });
  const [regenerate, setRegenerate] = useState({ fileId: "", status: false });
  const isMounted = useRef(false);
  // const [campaignId, setCampaignId] = useState(null);
  // const [selected, setSelected] = useState(null);
  // const [enableRowDetails, setEnableRowDetails] = useState(false);



  const handlePagination = (limit, offset) => {
    setPagination({ limit, offset })
  }

  const onDateChange = (date) => {
    setSelectedDate({
      startDate: date?.[0]?.startDate || date?.startDate,
      endDate: date?.[0]?.endDate || date?.endDate
    });
  }

  const handleFilterChange = (filter) => {
    setSearchText("");
    setChannel(filter);
  }

  const handleSearch = (search) => {
    isMounted.current = false;
    setSearchText(search);
  }

  const filterValues = {
    filterBy: channel.value,
    startDate: CalculateUserTzToUTCTz(selectedDate?.startDate).toISOString(),
    endDate: CalculateUserTzToUTCTz(endOfDay(selectedDate?.endDate)).toISOString()
  }

  const renderCells = (row) => {
    return <>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row.originalFileName}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.total ? row?.statusDetails?.total : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Queued ? row?.statusDetails?.Queued : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Sent ? row?.statusDetails?.Sent : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Delivered ? row?.statusDetails?.Delivered : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Undelivered ? row?.statusDetails?.Undelivered : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Failed ? row?.statusDetails?.Failed : '-'}</TableCell>
      <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>{row?.statusDetails?.Invalid ? row?.statusDetails?.Invalid : '-'}</TableCell>

    </>
  }

  /* eslint-disable */
  const renderActionButton = (row) => {
    return <div>
      {row.reconcileStatus === 'Processing' ? <span style={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }}>In Progress</span> :
        <a href="javascript:void(0)" style={{ color: appConfig.colors.blueprimary, fontSize: appConfig.font16 }} onClick={() => { reconcileFile(row.id, channel.value); setReconcile({ fileId: row.id, status: true }) }}>Reconcile</a>}
      {row.reconcileStatus === 'Processing' ? null : <a className='mar-l-10' href="javascript:void(0)" style={{ color: appConfig.colors.blueprimary, fontSize: appConfig.font16 }} onClick={() => { regenerateFile(row.id, channel.value); setRegenerate({ fileId: row.id, status: true }) }}>Regenerate</a>}
    </div>
  }

  // const renderDetailButton = (row) => {
  //   return <div>
  //     <IconButton className="pad-0" onClick={() => {
  //       window.sessionStorage.setItem("campaign", JSON.stringify(row))
  //       setEnableRowDetails(campaignId !== row.id ? true : !enableRowDetails);
  //       setCampaignId(row.id);
  //       setSelected(row.id)
  //       setTimeout(() => {
  //         snapshotRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //       }, 500);
  //     }} title='View Details' aria-label="view details" size="small">
  //       {campaignId === row.id && enableRowDetails ? <KeyboardArrowUpOutlinedIcon sx={{ border: `1px solid ${appConfig.colors.darkblue}`, fontSize: 30, borderRadius: "50%", color: appConfig.colors.darkblue }} /> : <KeyboardArrowDownOutlinedIcon sx={{ border: `1px solid ${appConfig.colors.darkblue}`, fontSize: 30, borderRadius: "50%", color: appConfig.colors.darkblue }} fontSize="inherit" />}
  //     </IconButton>
  //   </div>
  // }

  // const loadRowDetails = () => {
  //   return (<CampaignSnapshot id={campaignId} />);
  // }

  // useEffect(() => {
  //   loadRowDetails();
  // }, [campaignId]);

  useEffect(() => {
    if (reconcile.status && reconcileData?.reconcileFile?.isLoading) {
      const swalWithCustompButtons = Swal.mixin({
        customClass: {
          confirmButton: 'custom-ok-button',
        },
        buttonsStyling: false
      })
      swalWithCustompButtons.fire({
        text: "Reconcile is in progress",
        icon: "success",
        confirmButtonText: 'OK',
      }).then((res) => {
        if (res.isConfirmed) {
          fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset);
        }
      })
    } else {
      setReconcile({ fileId: "", status: false });
    }

    if (regenerate.status && reconcileData?.regenerateFile?.data?.status === 1) {
      const swalWithCustompButtons = Swal.mixin({
        customClass: {
          confirmButton: 'custom-ok-button',
        },
        buttonsStyling: false
      })

      swalWithCustompButtons.fire({
        text: "File Regeneration is in progress",
        icon: "success",
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset);
        }
      })
    }

  }, [JSON.stringify(reconcileData?.reconcileFile), JSON.stringify(reconcileData?.regenerateFile)])


  useEffect(() => {
    setBreadcrumb('Status Reconciliation')
    isMounted.current = true;
    fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset);
  }, [JSON.stringify(pagination), JSON.stringify(channel), JSON.stringify(selectedDate)])


  useEffect(() => {
    if (!isMounted.current) {
    setPagination({ limit: pagination.limit, offset: 1 })
    fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset);
    }
  }, [searchText])


  return <div>
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
      <h1 className='page-heading' ><b>Status Reconciliation</b></h1>
      </Grid>
      <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
        <hr className='blue-border' />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
        {reconcileData?.reconcileList?.isLoading ? <SkeletonGridLoader /> :
          <DefaultTable
            title="Total"
            handlePagination={handlePagination}
            // headers={['Filename', 'Total Records', 'Queued', 'Sent', 'Delivered', 'Undelivered', 'Failed']}
            headers={[{name:'Filename', sortEnabled:false},{name:'Total Records', sortEnabled:false},{name:'Queued', sortEnabled:false},{name:'Sent', sortEnabled:false},{name:'Delivered', sortEnabled:false},{name:'Undelivered', sortEnabled:false},{name:'Failed', sortEnabled:false},{name:'Invalid', sortEnabled:false}]}

            data={reconcileData?.reconcileList?.data?.files}
            totalCount={reconcileData?.reconcileList?.data?.totalCount}
            currentPage={pagination.offset}
            currentLimit={pagination.limit}
            isDateFilter
            selectedDateObj={(date) => onDateChange(date)}
            dateFilterLabel={`${moment(CalculateUTCTzToUserTz(selectedDate.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(selectedDate.endDate)).format('MM-DD-YYYY')}`}
            filterOptions={[{ key: "SMS", value: "sms" }, { key: "Email", value: "email" }]}
            isFilterEnabled
            filterAction={(filter) => { handleFilterChange(filter) }}
            defaultFilter={channel}
            isSearchEnabled
            searchCallback={(text) => handleSearch(text)}
            searchKey={searchText}
            searchPlaceholderText={'Search By Filename'}
            renderCustomCells
            renderCells={renderCells}
            hasAction
            renderActionButton={renderActionButton}
            isRefreshEnabled
            refreshCallback={() => { fetchReconcileList(filterValues, searchText, pagination.limit, pagination.offset) }}
            // hasDetails
            // enableRowDetails={enableRowDetails}
            // renderDetailsButton={renderDetailButton}
            // selected={selected}
            // loadRowDetails={enableRowDetails && loadRowDetails()}
          />}
      </Grid>
    </Grid>

  </div>
}


Reconcile.propTypes = {
  reconcileData: PropTypes.objectOf(PropTypes.object).isRequired,
  fetchReconcileList: PropTypes.func.isRequired,
  reconcileFile: PropTypes.func.isRequired,
  regenerateFile: PropTypes.func.isRequired,
  setBreadcrumb: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({ reconcileData: state.reconcile })

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReconcileList: (data, search, limit, page) => dispatch(reconcileActions.fetchReconcileList(data, search, limit, page)),
    reconcileFile: (fileId, channel) => dispatch(reconcileActions.fetchReconcileStatus(fileId, channel)),
    regenerateFile: (fileId, channel) => dispatch(reconcileActions.regenerateFile(fileId, channel)),
    setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Reconcile);


