import * as settingsConstants from './actionTypes';
import {config} from '../../config/config';
import request from '../../components/Helper/Request';

const userDetails = JSON.parse(window.sessionStorage.getItem("userInfo"));

export  function fetchEmailConfiguration(lang) {
    return (dispatch) => {
        dispatch({
            type: settingsConstants.FETCH_EMAIL_CONFIGURATION
        });
        request({
            method: 'get',
            url:`${config.API_URL}/api/settings/email/${userDetails?.accountId}/${userDetails?.userId}/${lang.toLowerCase()}`,   
        })  
        .then((response) => {
            dispatch({
            type: settingsConstants.FETCH_EMAIL_CONFIGURATION_SUCCESS,
            payload: response
            });
        })
        .catch((error) => {
            dispatch({
            type: settingsConstants.FETCH_EMAIL_CONFIGURATION_FAILURE,
            payload:error
            });
        })

    }
}

export  function createEmailConfiguration(headerConfig,footerConfig,subject,language) {

      const data = {
        accountId:userDetails?.accountId,
        headerConfig,
        footerConfig,
        subject,
        language
      }

    return (dispatch) => {
      dispatch({
        type: settingsConstants.CREATE_EMAIL_CONFIGURATION
      });
        request({
            method: 'post',
            url:`${config.API_URL}/api/settings/email/create/${userDetails?.userId}`,   
            data
        })  
        .then((response) => {
          dispatch({
            type: settingsConstants.CREATE_EMAIL_CONFIGURATION_SUCCESS,
            payload: response.data
          });
        })
        .catch((error) => {
          dispatch({
            type: settingsConstants.CREATE_EMAIL_CONFIGURATION_FAILURE,
            payload:error
          });
        })

    }
}


export  function updateEmailConfiguration(headerConfig,footerConfig,subject,language,id) {
  const data = {
    accountId:userDetails?.accountId,
    headerConfig,
    footerConfig,
    subject,
    language,
    id
  }

return (dispatch) => {
  dispatch({
    type: settingsConstants.UPDATE_EMAIL_CONFIGURATION
  });

    request({
        method: 'post',
        url:`${config.API_URL}/api/settings/email/update/${userDetails?.userId}`,   
        data
    })  
    .then((response) => {
      dispatch({
        type: settingsConstants.UPDATE_EMAIL_CONFIGURATION_SUCCESS,
        payload: response.data
      });
    })
    .catch((error) => {
      dispatch({
        type: settingsConstants.UPDATE_EMAIL_CONFIGURATION_FAILURE,
        payload:error
      });
    })

}


}




export  function fetchEmailConfigurationForTemplate(lang) {
  return (dispatch) => {
      dispatch({
          type: settingsConstants.FETCH_TEMPLATE_EMAIL_CONFIGURATION
      });
      request({
          method: 'get',
          url:`${config.API_URL}/api/settings/email/${userDetails?.accountId}/${userDetails?.userId}/${lang.toLowerCase()}`,   
      })  
      .then((response) => {
          dispatch({
          type: settingsConstants.FETCH_TEMPLATE_EMAIL_CONFIGURATION_SUCCESS,
          payload: response
          });
      })
      .catch((error) => {
          dispatch({
          type: settingsConstants.FETCH_TEMPLATE_EMAIL_CONFIGURATION_FAILURE,
          payload:error
          });
      })

  }
}


export  function resetEmailConfiguration() {
  return (dispatch) => {
      dispatch({
          type: settingsConstants.RESET_TEMPLATE_EMAIL_CONFIGURATION
      });
  
  }
}
