import * as actionTypes from '../actions/actionTypes';


const initialState = {
   emailSettings:{
    isLoading: false,
    isError: false,
    data:{
      header:"",
      footer:"",
      id:""
    },
   },
   createEmail:{
    isLoading: false,
    isError: false,
    data:"",
   },
   updateEmail:{
    isLoading: false,
    isError: false,
   },
  //  templateEmailSettings:{en:{header:"",footer:""},ht:{header:"",footer:""},pt:{header:"",footer:""},zh:{header:"",footer:""},vi:{header:"",footer:""},es:{header:"",footer:""}},
   templateEmailSettings:{}

  }

  const settingsReducer = (state = initialState, action = {}) => {

    const newState = { ...state };

  
    switch (action.type) {
      case actionTypes.FETCH_EMAIL_CONFIGURATION:
        return { ...newState, emailSettings: { ...newState.emailSettings, isLoading: true } };
  
      case actionTypes.FETCH_EMAIL_CONFIGURATION_SUCCESS:
        return { ...newState, emailSettings: {data:{header:action.payload.headerConfig, footer:action.payload.footerConfig,id:action.payload.id, subject:action.payload.subject},isLoading: false, isError: false} };

      case actionTypes.FETCH_EMAIL_CONFIGURATION_FAILURE:
        return { ...newState, emailSettings: {data:{...action.payload},isLoading: false, isError: true} };

        case actionTypes.CREATE_EMAIL_CONFIGURATION:
          return { ...newState, createEmail: { ...newState.createEmail, isLoading: true } };
    
        case actionTypes.CREATE_EMAIL_CONFIGURATION_SUCCESS:
          return { ...newState, createEmail: {data:{...action.payload},isLoading: false, isError: false} };
  
        case actionTypes.CREATE_EMAIL_CONFIGURATION_FAILURE:
          return { ...newState, createEmail: {data:{...action.payload},isLoading: false, isError: true} };

          case actionTypes.UPDATE_EMAIL_CONFIGURATION:
          return { ...newState, updateEmail: { ...newState.updateEmail, isLoading: true }, emailSettings: { ...newState.emailSettings, isLoading: true } };
    
        case actionTypes.UPDATE_EMAIL_CONFIGURATION_SUCCESS:
          return { ...newState, updateEmail:{...newState.updateEmail, isLoading:false,isError:false}, emailSettings: {data:{header:action.payload.headerConfig, footer:action.payload.footerConfig,id:action.payload?.id,subject:action.payload.subject}} };

        case actionTypes.UPDATE_EMAIL_CONFIGURATION_FAILURE:
          return { ...newState, updateEmail: {data:{...action.payload},isLoading: false, isError: true} };

          case actionTypes.FETCH_TEMPLATE_EMAIL_CONFIGURATION:
            return { ...newState, templateEmailSettings: {...newState.templateEmailSettings,isLoading: true, isError: false}};
      
          case actionTypes.FETCH_TEMPLATE_EMAIL_CONFIGURATION_SUCCESS:
            return { ...newState, templateEmailSettings: {...newState.templateEmailSettings,[action.payload.language]:{header:action.payload.headerConfig, footer:action.payload.footerConfig,id:action.payload.id, subject:action.payload.subject},isLoading: false, isError: false} };
    
          case actionTypes.FETCH_TEMPLATE_EMAIL_CONFIGURATION_FAILURE:
            return { ...newState, templateEmailSettings: {...newState.templateEmailSettings,isLoading: false, isError: true} };

            case actionTypes.RESET_TEMPLATE_EMAIL_CONFIGURATION:
              return { ...newState, templateEmailSettings: {} }
    
      default:
        return { ...newState };
    }
  };
  
  
  export default settingsReducer;