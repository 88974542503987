import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material'
import Backdrop from '@mui/material/Backdrop';
import ReactFileReader from "react-file-reader";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import FileUpload from '../components/Form/FileUpload';
import uploadIcon from '../assets/icons/upload.svg';
import * as Styled from '../components/styled-components/Campaign';
import SpinningLoader from '../components/SpinningLoader';
import RadioButtonGroup from '../components/RadioButtonGroup';

import appConfig from '../config/appConfig';
import * as campaignActions from '../store/actions/campaign';
import * as menuActions from '../store/actions/breadcrumb';


const UploadFile = (props) => {
    const { campaignData, uploadFile } = props;
    const [selectedFile, setSelectedFile] = useState(campaignData.uploadedFile || null);
    const [isError, setError] = useState({ isErrorFile: campaignData.isErrorFile || false, showErrorMsg: false });
    const [isLoading, setIsLoading] = useState(false);
    const [channel, setChannel] = useState("SMS");
    const [isFileUploaded,setIsFileUploaded] = useState(false);
    const { setBreadcrumb } = props;
    const navigate = useNavigate();

    const validateFile = (file) => {
        if (file.name && file.name.split('.')[1] === 'dat') {
            return true;
        }
        const swalWithCustompButtons = Swal.mixin({
            customClass: {
                confirmButton: 'custom-ok-button',
            },
            buttonsStyling: false
        })
        swalWithCustompButtons.fire({
            title: "",
            text: 'Please choose only .dat format file',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: 'Ok',
        })
        return false
    };

    useEffect(() => {
        setBreadcrumb('Upload Data File')
    }, [])


    useEffect(() => {
        if (campaignData?.uploadFile && campaignData?.uploadFile?.data?.status === 1 && isFileUploaded) {
            setIsLoading(false);
            setIsFileUploaded(false);
            Swal.fire({
                icon: "success",
                button: false,
                showConfirmButton: false,
                timer: 2500,
                text: "File uploaded successfully",
            });
            setTimeout(() => {
                navigate('/campaign')
            }, 2500)
        } else if(campaignData?.uploadFile?.data?.status === 0 && isFileUploaded) {
            setIsFileUploaded(false);
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                button: false,
                showConfirmButton: false,
                timer: 2500,
                text: "Error while uploading file. Please try again",
            });
        }
    }, [JSON.stringify(campaignData.uploadFile)]);

    const uploadFileCallback = (file) => {
        return new Promise(
            () => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener('load', () => {
                    window.open(URL.createObjectURL(new Blob([file], {type: 'text/plain;charset=ISO-8859-1'})));
                });
            }
        );
    }

    const fileOpener = async () => {
        // window.open(URL.createObjectURL(selectedFile));
        uploadFileCallback(selectedFile)
    }
    const sendFormValues = () => {
        setIsLoading(true);
        setIsFileUploaded(true);
        const data = {
            uploadedFile: selectedFile,
            channel: channel.toLowerCase()
        }
        uploadFile(data);
    }

    const checkConfirmation = () => {
        if (selectedFile) {
            const swalWithCustompButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'custom-ok-button',
                    cancelButton: 'custom-cancel-button'
                },
                buttonsStyling: false
            })
            swalWithCustompButtons.fire({
                title: "",
                text: 'Upload data file?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Upload',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    sendFormValues();
                }
            })
        } else {
            setError({ showErrorMsg: true })
        }
    }

    const handleFormSubmit = () => {
        checkConfirmation();
    }

    const handleContentChange = (event) => {
        setChannel(event.target.value === "Email" ? "email" : "sms");
    };

    const handleFiles = (files) => {
        setSelectedFile(files[0]);
        const reader = new FileReader();
        reader.onloadend = () => {
            const isValid = validateFile(files[0]);
            if (isValid) {
                setError((prevState) => ({
                    ...prevState,
                    isErrorFile: false,
                    showErrorMsg: false
                }));
            } else {
                setSelectedFile(null);
                setError((prevState) => ({
                    ...prevState,
                    isErrorFile: true,
                    showErrorMsg: true
                }));
            }
        };
        reader.readAsText(files[0]);

    };

    return <div>
        {isLoading ? <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <SpinningLoader circleColor={appConfig.colors.white} label="Uploading File" />
        </Backdrop> : null}

        <Grid container spacing={2}>
            <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
                <h1 style={{ margin: 0, fontSize: "21px" }}><b>Upload Data File</b></h1>
            </Grid>
            <Grid item xs={6} xl={6} lg={6} md={6}>
                    <div className='text-align-right'>
                        <Styled.CampaignButton
                            className='text-trans-none'
                            variant="contained"
                            onClick={() => navigate('/campaign')}
                        >
                            Campaigns
                        </Styled.CampaignButton>
                    </div>
                </Grid>
        </Grid>
        <hr className='blue-border' />
        <Grid container spacing={2}>
            <Grid item md={7} lg={7} sm={12} xs={12}>
                <Typography component="p" sx={{ mt: 1, mb: 2 }}>Communication Channel</Typography>
                    <RadioButtonGroup
                        key={channel}
                        className={['create-using-account-radio', channel === "email" ? "email" : "sms"].join(' ')}
                        value={channel}
                        labels={['SMS', 'Email']}
                        handleChange={handleContentChange}
                    />
            </Grid>
            <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
                <div>
                    <Typography className="form-ctrl-main-heading inline-blk" >Upload Data File (Required)</Typography>
                    <Grid item xs={12} sm={12} xl={7} lg={7} md={7}>
                        <ReactFileReader
                            multipleFiles
                            fileTypes={[".dat"]}
                            handleFiles={handleFiles}
                        >
                            <FileUpload selectedFile={selectedFile || campaignData.uploadedFile}
                                showFileName
                                fileType={false}
                                file=".dat"
                                // name="Upload"
                                icon={<img alt="Upload icon" src={uploadIcon} />
                                }
                            />
                        </ReactFileReader>
                    </Grid>
                </div>
                {isError.showErrorMsg ? <div><p style={{ color: "red" }}>{isError.isErrorFile ? "Please upload valid file." : "Please upload a file."}</p></div> : null}
            </Grid>
            {selectedFile ? <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
                <div className='mar-l-10 inline-blk'>
                    <p><b>{selectedFile ? selectedFile.name : ''}</b></p>
                </div>
                <div className='mar-l-10 inline-blk'>
                    <Button onClick={fileOpener} sx={{ color: appConfig.colors.darkblue, textTransform: 'capitalize', textDecoration: "underline", fontSize: appConfig.font16 }}>
                        Preview
                    </Button>
                </div>

            </Grid> : null}
            <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
                <hr className='blue-border' />
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} xl={2} lg={2} md={2}>
                <Styled.CancelButton
                    variant="outlined"
                    sx={{ mt: 3, ml: 1, fontFamily: appConfig.fontfamily, textTransform: 'capitalize', width: "100%", height: "50px" }}
                    color="primary"
                    onClick={() => {
                        navigate('/campaign')
                    }}
                >
                    Cancel
                </Styled.CancelButton>
            </Grid>
            <Grid item xs={12} sm={7.8} xl={4.9} lg={4.9} md={5}>
                <Styled.CampaignButton
                    variant="contained"
                    sx={{ mt: 3, ml: 1, fontFamily: appConfig.fontfamily, textTransform: 'capitalize', width: "100%", height: "50px" }}
                    color="primary"
                    onClick={() => handleFormSubmit()}
                    disabled={isError.isErrorFile}
                >
                    Upload
                </Styled.CampaignButton>
            </Grid>
        </Grid>
    </div >
}

UploadFile.propTypes = {
    campaignData: PropTypes.arrayOf(PropTypes.array).isRequired,
    uploadFile: PropTypes.func.isRequired,
    setBreadcrumb: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (data) => dispatch(campaignActions.uploadFile(data)),
        setBreadcrumb: (menu) => dispatch(menuActions.changeMenu(menu))
    };
}
const mapStateToProps = (state) => ({ campaignData: state.campaign })


export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);