import * as usersConstants from './actionTypes';
import {config} from '../../config/config';
import request from '../../components/Helper/Request';

export const getUsersList = (accountId,limit,page) => {
    return (dispatch) => {
        dispatch({
            type: usersConstants.FETCH_USERS_LIST
        });
        request({
            method: 'get',
            url:`${config.API_URL}/api/users/list/${accountId}/${limit}/${page}`,
        })
        .then((response) => {
            dispatch({
                type: usersConstants.FETCH_USERS_LIST_SUCCESS,
                payload: response
            });
        })
        .catch((error) => {
            dispatch({
                type: usersConstants.FETCH_USERS_LIST_FAILURE,
                payload: error.response.data
            });
        })
    }  
}

export const createUser = (signupData) => {
    const data = {
        'firstName':signupData.firstName,
        'lastName': signupData.lastName,
        'emailId': signupData.email,
        'roleId': signupData.role,
        'accountId': signupData.accountId,
        'createdBy':signupData.createdBy
    };
    return (dispatch) => {
        dispatch({
          type: usersConstants.SIGN_UP,
        });
       
        request({
            method: 'post',
            url:`${config.API_URL}/api/users/add/`,   
            data
        })
        .then((response) => {
          dispatch({
            type: usersConstants.SIGN_UP_SUCCESS,
            payload: response
          });
        })
        .catch((error) => {
          dispatch({
            type: usersConstants.SIGN_UP_FAILURE,
            payload: error.response.data
          });
        })
    }
}

export const updateUser = (data) => {
  return (dispatch) => {
    dispatch({
        type: usersConstants.SET_EDIT_USER_DATA,
        payload:data
    });
  }
}

export const editUser = (editData) => {
  const data = {
      'firstName':editData.firstName,
      'lastName': editData.lastName,
      'roleId': editData.role,
      'accountId': editData.accountId,
      'id':editData.id,
      'modifiedBy':editData.modifiedBy
  };
  return (dispatch) => {
      dispatch({
        type: usersConstants.EDIT_USER,
      });
     
      request({
          method: 'post',
          url:`${config.API_URL}/api/users/edit/`,   
          data
      })
      .then((response) => {
        dispatch({
          type: usersConstants.EDIT_USER_SUCCESS,
          payload: response
        });
      })
      .catch((error) => {
        dispatch({
          type: usersConstants.EDIT_USER_FAILURE,
          payload: error.response.data
        });
      })
  }
}



export const deleteUser = (id,accId,modifiedBy) => {
    const data = {
        id,
        accountId :accId,
        modifiedBy
     };
    return (dispatch) => {
        dispatch({
          type: usersConstants.DELETE_USER,
        });
       
        request({
            method: 'post',
            url:`${config.API_URL}/api/users/delete/`,   
            data
        })
        .then((response) => {
          dispatch({
            type: usersConstants.DELETE_USER_SUCCESS,
            payload: response
          });
        })
        .catch((error) => {
          dispatch({
            type: usersConstants.DELETE_USER_FAILURE,
            payload: error.response.data
          });
        })

    }
}

