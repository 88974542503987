import React from 'react';
import { Modal } from "@mui/material";
import Box from '@mui/material/Box';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PropTypes from 'prop-types';
import * as Styled from './styled-components/Campaign';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const ModalPopup = ({
    open,
    onClose,
    onSubmit = "",
    children,
    submitLabel = "",
    submitBtn = false,
    closeBtn = true,
    download = false,
    downloadSampleFile,
    // downloadBtnlabel = ""
}) => {
    const handleClose = () => {
        onClose(false);
    };

    const handleSubmit = (data) => {
        onSubmit(data);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {children}
               
                { (submitBtn || closeBtn) && ( 
                    <>
                        <br />
                        <div style={{ textAlign: "right" }}>
                            {submitBtn && (
                                <><Styled.CampaignButton type="submit" variant="contained" onClick={handleSubmit}>{submitLabel}</Styled.CampaignButton>&nbsp;&nbsp;</>
                            )}
                            {download && (
                            <Styled.CancelButton title='Download Sample File'  onClick={downloadSampleFile}>
                                <FileDownloadIcon
                                aria-label="Download"
                                size="small" />
                                </Styled.CancelButton>
                            )}
                            {
                                closeBtn && (
                                    <Styled.CancelButton variant="outlined" onClick={handleClose}>Close</Styled.CancelButton>
                                )
                            }
                        </div>
                    </>
                )}
            </Box>
        </Modal>
    );
}

ModalPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    submitBtn: PropTypes.bool.isRequired,
    closeBtn: PropTypes.bool.isRequired,
    submitLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    download:PropTypes.bool.isRequired,
    downloadSampleFile:PropTypes.func.isRequired,
    // downloadBtnlabel:PropTypes.string.isRequired
}

export default ModalPopup;