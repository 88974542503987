import * as actionTypes from '../actions/actionTypes';


const initialState = {
   optout:{
    isLoading: false,
    isError: false,
    data:{},
   },
   optoutList:{
    isLoading: false,
    isError: false,
    data:{},
   }
  }

  const optoutReducer = (state = initialState, action = {}) => {

    const newState = { ...state };
  
    switch (action.type) {
      case actionTypes.TRIGGER_OPT_OUT:
        return { ...newState, optout: { ...newState.optout, isLoading: true } };
  
      case actionTypes.TRIGGER_OPT_OUT_SUCCESS:
        return { ...newState, optout: {data:{...action.payload},isLoading: false, isError: false} };

      case actionTypes.TRIGGER_OPT_OUT_FAILURE:
        return { ...newState, optout: {data:{...action.payload},isLoading: false, isError: true} };

        case actionTypes.FETCH_OPT_OUT_LIST:
          return { ...newState, optoutList: { ...newState.optoutList, isLoading: true } };
    
        case actionTypes.FETCH_OPT_OUT_LIST_SUCCESS:
          return { ...newState, optoutList: {data:{...action.payload},isLoading: false, isError: false} };
  
        case actionTypes.FETCH_OPT_OUT_LIST_FAILURE:
          return { ...newState, optoutList: {data:{...action.payload},isLoading: false, isError: true} };
  
      default:
        return { ...newState };
    }
  };
  
  
  export default optoutReducer;