import * as actionTypes from '../actions/actionTypes';

const initialState = {
  users: {
    users:[],
    count: "",
    isLoading: false,
    isError: false
  },
  registerDetails: {},
  errorMessage: {},
  isLoading: false,
  isError: false,
  deleteUser:{}
}

const usersReducer = (state = initialState, action = {}) => {

  const newState = { ...state };

  switch (action.type) {
    case actionTypes.FETCH_USERS_LIST:
        return { ...newState, users: { ...newState.users, isLoading: true }  };
  
    case actionTypes.FETCH_USERS_LIST_SUCCESS:
        return { ...newState,  users: { users: [...action.payload.data], count:action.payload.totalCount, isLoading: false, isError: false} };
        // return { ...newState, campaigns: {campaigns:[...action.payload.data],count:action.payload.totalCount,isLoading: false}  };

    case actionTypes.FETCH_USERS_LIST_FAILURE:
        return {  isLoading: false, users: { users: [...action.payload.data], count:action.payload.totalCount, isLoading: false, isError: true} };
    
    case actionTypes.SIGN_UP:
        return {...newState , isLoading: true};
        
    case actionTypes.SIGN_UP_SUCCESS:
        return {...newState , isLoading: false, isError:false, registerDetails:{...action.payload}, errorMessage: {}};

    case actionTypes.SIGN_UP_FAILURE:
        return {...newState , isLoading: false, isError:true, errorMessage: {...action.payload}, registerDetails: {} };

    case actionTypes.DELETE_USER:
        return {...newState , isLoading: true, deleteUser:{}};
          
    case actionTypes.DELETE_USER_SUCCESS:
        return {...newState , isLoading: false, isError:false, deleteUser:{...action.payload}, errorMessage: {}};
  
    case actionTypes.DELETE_USER_FAILURE:
        return {...newState , isLoading: false, isError:true, errorMessage: {...action.payload}, deleteUser: {} };

    case actionTypes.SET_EDIT_USER_DATA:
        return { ...newState, isLoading: false, isError: false, editUser: { ...action.payload }, editUserData: {} };

        case actionTypes.EDIT_USER:
            return {...newState , isLoading: true};
            
        case actionTypes.EDIT_USER_SUCCESS:
            return {...newState , isLoading: false, isError:false, editUser:{...action.payload}, errorMessage: {}};
    
        case actionTypes.EDIT_USER_FAILURE:
            return {...newState , isLoading: false, isError:true, editUser: {...action.payload}};
      
    default:
      return { ...newState };
  }
};


export default usersReducer;