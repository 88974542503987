/* eslint-disable */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import ClearIcon from '@mui/icons-material/Clear';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortIcon from '@mui/icons-material/Sort'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';

import { TextField, Grid } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import FilterDropDown from './DropDown';
import DateRangePickerComponent from './DateRangePicker/DateRangePickerComponent';
import * as Styled from './styled-components/Campaign';
import appConfig from '../config/appConfig';
import { containsSpecialCharactersGrid } from './Utils';


const SearchButtonStyle = {
  textTransform: 'none',
  height: "38px",
  borderRadius: "0 5px 5px 0",
}

const TableHeaderStyle = {
  fontFamily: appConfig.fontfamily,
  fontSize: appConfig.font16,
  verticalAlign: "top"
}

const datePickerStyle = {
  background: "#FFF",
  marginLeft: "5px",
  marginRight: "5px",
  textTransform: "capitalize",
  borderColor: "#005974",
  color: "#000",
  height: "38px",
  padding: "0 20px 0 5px"
}

const DefaultTable = ({ headers, data = [], accessor = [], renderCustomCells, renderCells, hasAction, renderActionButton, handlePagination, currentLimit, currentPage, filterAction, isFilterEnabled, isDateFilter = false, dateFilterLabel, selectedDateObj, totalCount, isSearchEnabled, searchPlaceholderText, filterOptions, defaultFilter, selected = null, loadRowDetails = null, enableRowDetails = false, searchCallback, searchKey, hasDetails, renderDetailsButton, isExportEnabled, exportCallback, isRefreshEnabled = false, refreshCallback, isSortEnabled, handleSort,sortValue }) => {
  const [page, setPage] = React.useState(currentPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(currentLimit);
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);
  const [searchText, setSearchText] = useState(searchKey);
  const [orderBy,setOrderBy]= useState({order:sortValue.sortOrder,field:sortValue.sortBy});
  const searchRef = useRef();

  const handleDummy = (e, nextPage) => {
    handlePagination(rowsPerPage, nextPage);
    setPage(nextPage);
  }

  const onLimitChange = (limit) => {
    setRowsPerPage(limit.value);
    handlePagination(limit.value, 1);

  }

  const handleSearchValue = (val) => {
    if(!containsSpecialCharactersGrid(val)){
      setSearchText(val)
    }
  }

  const checkKeyPress = useCallback((e) => {
    const { key } = e;
    if (key === "Enter"){
      searchCallback(searchText.trim())
    }
  },[searchText]);


  useEffect(()=>{
    if(searchRef.current)
   searchRef.current.addEventListener("keypress", checkKeyPress);
  },[searchText]);



  const handleSorting = (header) =>{
    if(header.sortEnabled){
      setOrderBy({order:orderBy.order === "asc" ? "desc" : "asc",field:header.name});
      const sortObj = {sortBy:header.sortBy,sortOrder:orderBy.order === "asc" ? "desc" : "asc"}
      handleSort(sortObj);
      handlePagination(rowsPerPage, 1);
    }
  }

  const renderHeaders = headers.map((header) => <TableCell  sx={TableHeaderStyle} key={header.name}>
    <b style={{cursor:"pointer"}} onClick={()=>{handleSorting(header)}}>{header.name}</b>
    {isSortEnabled && header.sortEnabled  ? 
    <IconButton  onClick={()=>{handleSorting(header)}} className="pad-0" title={`${orderBy.order  === "desc" ? "descending" : "ascending" }`} aria-label="sort by" size="small">
        { orderBy.order  === "desc"    ? <ArrowDownwardIcon sx={{fontSize:"17px"}} /> : <ArrowUpwardIcon sx={{fontSize:"17px"}}/>}
      </IconButton> : header.sortEnabled ? <SortIcon sx={{fontSize:"17px",verticalAlign:"middle", marginLeft:"5px"}}/> : null }
    </TableCell>)
  const renderTableBody = data?.length === 0 ? <TableRow><TableCell key={0} sx={TableHeaderStyle} >No Data</TableCell></TableRow> : data?.map((row) => {
    const accessKey = accessor.map(key => key);
    return <>
      <TableRow
        key={row.index}
        sx={{
          background: '#fafafa',
          ' &.Mui-selected': {
            background: selected === row.id ? '#fafafa' : '',
            '&:hover': {
              background: '#fafafa'
            }
          }
        }}
        selected={selected === row.id}
      >
        {renderCustomCells ? renderCells(row) : accessKey.map(dataKey => {
          if (dataKey.includes('[')) {
            return <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }} key={dataKey.index}>{row[dataKey.split('[')[0]][dataKey.split('[')[1].replace(']', '')] === null ? "-" : row[dataKey.split('[')[0]][dataKey.split('[')[1].replace(']', '')]}</TableCell>
          }
          return <TableCell sx={{ fontFamily: appConfig.fontfamily, fontSize: appConfig.font16 }} key={dataKey.index}>{row[dataKey] === null ? "-" : row[dataKey]}</TableCell>
        })
        }
        {hasAction ? <TableCell key="action" sx={{ fontFamily: appConfig.fontfamily }}>{renderActionButton(row)}</TableCell> : null}
        {hasDetails ? <TableCell key="details">{renderDetailsButton(row)}</TableCell> : null}

      </TableRow>
      {selected === row.id && enableRowDetails && (
        <TableRow>
          <TableCell colSpan={12}>
            <Accordion>
              <AccordionDetails sx={{ border: "1px solid #ccc" }}>
                {loadRowDetails}
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      )}
    </>
  })


  return (
    <Grid container spacing={2}>
      <Grid container>
        <Grid item xs={12} sm={12} xl={3.5} lg={3.5} md={12}>
          {totalCount > 0 ?
            <div className='inline-blk' style={{ color: '#757575', paddingLeft: "20px" }}>
              <p>{`Viewing: ${page > 1 ? (page - 1) * rowsPerPage + 1 : page} - ${(page * rowsPerPage) > totalCount ? totalCount : page * rowsPerPage} of ${totalCount || 0} records`}</p>
            </div> : null}
          {totalCount > 0 && isRefreshEnabled ? <IconButton onClick={refreshCallback} sx={{ background: appConfig.colors.grey, marginLeft: "20px" }} aria-label="refresh">
            <RefreshIcon />
          </IconButton> : null}
        </Grid>
        <Grid item xs={12} sm={12} xl={8.5} lg={8.5} md={12} sx={{marginTop:"10px", marginLeft:{sm:"20px",lg:0,xl:0,md:0}}}>
          <Grid container spacing={{ xs: 0, sm: 1, md: 0,lg:1,xl:1 }} rowSpacing={{ xs: 1, sm:1 }} sx={{display:"flex",alignContent: {xs:"flex-start", sm:"flex-start", lg:"flex-end", xl:"flex-end",md:"flex-start"} ,justifyContent:{xs:"flex-start", sm:"flex-start", lg:"flex-end", xl:"flex-end",md:"flex-start"},marginTop:"10px"}}>
            {isFilterEnabled ? <Grid item >
              <FilterDropDown dropdownHeight="38px" selectedValue={selectedFilter}
                selectOptions={filterOptions} onSelect={(filter) => {
                  filterAction(filter);
                  setSelectedFilter(filter);
                }}
                icon={<ArrowDropDownIcon sx={{
                  color: '#005974',
                }} />}
              /></Grid> : null}

            {
              isDateFilter ?
                <Grid item >
                  <DateRangePickerComponent label={dateFilterLabel} selectedDateObj={(date) => { selectedDateObj(date) }} cssStyle={datePickerStyle} /></Grid> : null
            }


            {isSearchEnabled ? <Grid item >
              <div style={{ display: "inline-block", position: "relative" }}> <TextField ref={searchRef}
                placeholder={searchPlaceholderText || "Search"} value={searchText} onChange={(e) => handleSearchValue(e.target.value)} sx={
                  {
                    '& .MuiInputBase-input': {
                      backgroundColor: '#fcfcfb',
                      fontSize: 14,
                      padding: "7px",
                      height: "23px",
                      width: "210px",
                      border: "1px solid #005974",
                      borderRadius: "5px 0 0 5px",
                      outline: "none"
                    }

                  }
                }
              />
                {searchText.length > 0 ? <ClearIcon onClick={() => {
                  setSearchText('');
                  searchCallback('');
                }} fontSize="small" sx={{ position: 'absolute', right: 80, top: 8, color: appConfig.colors.blueprimary }} /> : null}

                <Styled.CancelButton
                  style={{ ...SearchButtonStyle }}
                  variant="contained"
                  onClick={() => searchCallback(searchText.trim())}
                >
                  Search
                </Styled.CancelButton>
              </div> </Grid> : null}

            {isExportEnabled ? <Grid item >
              <Styled.CancelButton
                style={{ marginLeft: "5px" }}
                variant="contained"
                onClick={exportCallback}
              >
                Export
              </Styled.CancelButton> </Grid>
              : null}
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
        {totalCount > 0 && <div style={{ border: "1px solid #e5e5e5", height: "1px", width: "100%", marginTop: "18px" }} />}
      </Grid>

      {/* <hr style={{border:"3px solid #EDF6F5", margin:"20px 0"}}/> */}

      <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
        <div style={{
          overflowY: "auto",
        }}>
          {data?.length !== 0 ? <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <>
                    {renderHeaders}
                    {hasDetails && <TableCell style={TableHeaderStyle} key="Details" />}
                    {hasAction && <TableCell style={TableHeaderStyle} key="Actions" />}
                  </>
                </TableRow>
              </TableHead>
              <TableBody tabIndex="0">
                {renderTableBody}
              </TableBody>
            </Table>
          </TableContainer> : <div style={{ color: appConfig.colors.darkgray, textAlign: "center", marginTop: "8%" }}> <FormatListBulletedIcon sx={{ fontSize: 80, fontFamily: appConfig.fontfamily }} /><h2 style={{ fontSize: appConfig.font16 }}>No Data</h2></div>}
        </div>
        <br />
        {data?.length !== 0 && (
          <div style={{ float: "right", display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <FilterDropDown isEnableFirstItem={false} selectedValue={{ key: currentLimit, value: currentLimit }} selectOptions={[{ key: currentLimit, value: currentLimit }, { key: '10', value: '10' }, { key: '50', value: '50' }, { key: '100', value: '100' }]} onSelect={onLimitChange} />
            </div>
            <Pagination page={page} onChange={handleDummy} count={Math.ceil(totalCount / rowsPerPage)} 
            
            sx={{
              "& .MuiPaginationItem-root": {
                color: appConfig.colors.darkblue,
              }
            
            }}
            
            variant="outlined" shape="rounded" />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

DefaultTable.defaultProps = {
  isDateFilter: false,
  isSearchEnabled: false,
  enableRowDetails: false,
  loadRowDetails: null,
  selected: null,
  renderActionButton: null,
  hasDetails: false,
  hasAction: false,
  renderDetailsButton: null,
  isExportEnabled: false,
  exportCallback: null,
  isRefreshEnabled: false,
  refreshCallback: null,
  accessor: [],
  selectedDateObj: null,
  handleSort: null ,
  isSortEnabled: false,
  sortValue:{
    "sortBy": "",
    "sortOrder": "desc"
}
};

DefaultTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  accessor: PropTypes.arrayOf(PropTypes.string),
  hasAction: PropTypes.bool,
  renderActionButton: PropTypes.func,
  hasDetails: PropTypes.bool,
  renderDetailsButton: PropTypes.func,
  handlePagination: PropTypes.func.isRequired,
  currentLimit: PropTypes.number.isRequired,
  // title: PropTypes.string.isRequired,
  searchPlaceholderText: PropTypes.string.isRequired,
  filterAction: PropTypes.func.isRequired,
  isSearchEnabled: PropTypes.bool,
  isFilterEnabled: PropTypes.bool.isRequired,
  isDateFilter: PropTypes.bool,
  selectedDateObj: PropTypes.func,
  dateFilterLabel: PropTypes.string.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.string.isRequired,
  defaultFilter: PropTypes.object.isRequired,
  selected: PropTypes.number,
  enableRowDetails: PropTypes.bool,
  searchCallback: PropTypes.func.isRequired,
  searchKey: PropTypes.string.isRequired,
  renderCells: PropTypes.func.isRequired,
  renderCustomCells: PropTypes.bool.isRequired,
  loadRowDetails: PropTypes.func,
  isExportEnabled: PropTypes.bool,
  isRefreshEnabled: PropTypes.bool,
  exportCallback: PropTypes.func,
  refreshCallback: PropTypes.func,
  handleSort: PropTypes.func,
  isSortEnabled: PropTypes.bool.isRequired,
  sortValue:PropTypes.object,
}


export default React.memo(DefaultTable);