import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField } from '@mui/material'
// import {Button} from '@mui/material'
import Backdrop from '@mui/material/Backdrop';
// import ReactFileReader from "react-file-reader";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { useForm } from 'react-hook-form';
// import FileUpload from '../components/Form/FileUpload';
// import uploadIcon from '../assets/icons/upload.svg';
import * as Styled from '../components/styled-components/Campaign';
import SpinningLoader from '../components/SpinningLoader';
import RadioButtonGroup from '../components/RadioButtonGroup';
import { trimStringSpace } from '../components/Utils';
import appConfig from '../config/appConfig';
import { SET_CREATE_CAMPAIGN_VALUES, CLEAR_CREATE_CAMPAIGN_VALUES } from '../store/actions/actionTypes';
import * as campaignActions from '../store/actions/campaign';

const ErrorStyle = {
    color: "red",
    display: 'flex',
    marginTop: '5px'
}
const textboxCss = {
    border: `1px solid ${appConfig.colors.darkblue}`,
    borderRadius: "4px",
    outline: "none",
    '&.MuiFormControl-root': {
        margin: "0"
    },
    '&.MuiTextField-root': {
        backgroundColor: '#fff'
    },
    '& .MuiInputBase-input': {
        backgroundColor: '#fff',
        fontSize: 16,
        fontFamily: appConfig.fontfamily,
        padding: "10px 14px",
        borderRadius: "4px",
        outline: "none",

    },
    '& .MuiOutlinedInput-root': {
        padding: '0',
    }
};


const CreateCampaign = (props) => {
    const params = useLocation();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { clearCampaignValues, campaignData, createNewCampaign } = props;
    // const [selectedFile, setSelectedFile] = useState(campaignData.uploadedFile || null);
    // eslint-disable-next-line
    const [isError, setError] = useState({ isErrorFile: campaignData.isErrorFile || false, showErrorMsg: false });
    const [isLoading, setIsLoading] = useState(false);
    const [isCampaignSubmitted, setIsCampaignSubmitted] = useState(false);
    const [channel, setChannel] = useState("SMS");
    const navigate = useNavigate();



    // const validateFile = (file) => {
    //     if (file.name && file.name.split('.')[1] === 'dat') {
    //         return true;
    //     }
    //     const swalWithCustompButtons = Swal.mixin({
    //         customClass: {
    //             confirmButton: 'custom-ok-button',
    //         },
    //         buttonsStyling: false
    //     })
    //     swalWithCustompButtons.fire({
    //         title: "",
    //         text: 'Please choose only .dat format file',
    //         icon: 'error',
    //         showCancelButton: false,
    //         confirmButtonText: 'Ok',
    //     })
    //     return false
    // };

    useEffect(() => {
        clearCampaignValues()
    }, [params?.state?.initState])


    useEffect(() => {
        if (campaignData.createCampaignData && campaignData.createCampaignData.status === 1 && isCampaignSubmitted && !campaignData?.isLoading) {
            setIsLoading(false);
            setIsCampaignSubmitted(false);
            Swal.fire({
                icon: "success",
                button: false,
                showConfirmButton: false,
                timer: 2500,
                text: "Campaign created successfully",
            });
            setTimeout(() => {
                navigate("/campaign");
            }, 2500)
        } else if (campaignData?.isError && !campaignData?.isLoading && isCampaignSubmitted) {
            setIsCampaignSubmitted(false);
            setIsLoading(false);
            Swal.fire({
                icon: "error",
                button: false,
                showConfirmButton: false,
                timer: 2500,
                text: campaignData.createCampaignData.message,
            });
        }
    }, [campaignData?.createCampaignData?.status,campaignData?.isError, campaignData?.isLoading]);


    // const fileOpener = () => {
    //     window.open(URL.createObjectURL(selectedFile));
    // }

 
    const sendFormValues = (formData) => {
        setIsLoading(true);
        setIsCampaignSubmitted(true);
        const data = {
            // uploadedFile: selectedFile,
            name: trimStringSpace(formData.name),
            campaignDescription: formData.description,
            channel: channel.toLowerCase()
        }
        createNewCampaign(data)
    }

    const checkConfirmation = (formDat) => {
        // if (selectedFile) {
            const swalWithCustompButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'custom-ok-button',
                    cancelButton: 'custom-cancel-button'
                },
                buttonsStyling: false
            })

            swalWithCustompButtons.fire({
                title: "",
                text: 'Create campaign?',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Create',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    sendFormValues(formDat);
                }
            })
        
        // } else {
        //     setError({ showErrorMsg: true })
        // }
    }

    
    const handleFormSubmit = (data) => {
        // sendFormValues(data)
        if(!Object.keys(errors).length){
            checkConfirmation(data);
        }
    }

    const handleContentChange = (event) => {
        setChannel(event.target.value === "Email" ? "email" : "sms");
    };

    // const handleFiles = (files) => {
    //     setSelectedFile(files[0]);
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         const isValid = validateFile(files[0]);
    //         if (isValid) {
    //             setError((prevState) => ({
    //                 ...prevState,
    //                 isErrorFile: false,
    //                 showErrorMsg: false
    //             }));
    //         } else {
    //             setSelectedFile(null);
    //             setError((prevState) => ({
    //                 ...prevState,
    //                 isErrorFile: true,
    //                 showErrorMsg: true
    //             }));
    //         }
    //     };
    //     reader.readAsText(files[0]);

    // };

    return <div>
        {isLoading ? <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <SpinningLoader circleColor={appConfig.colors.white} label="Creating Campaign" />
        </Backdrop> :  null }

            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} xl={6} lg={6} md={6}>
                    <h1 style={{ margin: 0, fontSize: "21px" }}><b>Create Campaign</b></h1>
                </Grid>
                <Grid item xs={6} xl={6} lg={6} md={6}>
                    <div className='text-align-right'>
                        <Styled.CampaignButton
                            className='text-trans-none'
                            variant="contained"
                            onClick={() => navigate('/campaign')}
                        >
                            Campaigns
                        </Styled.CampaignButton>
                    </div>
                </Grid>
            </Grid>
            <hr className='blue-border' />
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <Grid container spacing={2}>

                    <Grid item md={7} xl={7} lg={7} sm={12} xs={12}>
                        <div >
                            <Typography component="p" sx={{ mb: 1 }}>Name (Required)</Typography>
                            <TextField
                                {...register('name', { required: true, pattern: /^[A-Za-z0-9 ]+$/})}
                                margin="normal"
                                id="name"
                                sx={textboxCss}
                                fullWidth
                                inputProps={{ "aria-label": "campaign name" }}
                            />
                        </div>
                        {errors?.name?.type === "required" && <span style={ErrorStyle}>Please enter campaign name.</span>}
                        {errors?.name?.type === "pattern" && (<span style={ErrorStyle}>Special characters not allowed.</span>)}
                    </Grid>
                    <Grid item md={7} xl={7} lg={7} sm={12} xs={12}>
                        <div >
                            <Typography component="p" sx={{ mb: 1 }}>Description</Typography>
                            <TextField
                                {...register('description', {pattern: /^[A-Za-z0-9 ]+$/})}
                                margin="normal"
                                id="description"
                                sx={textboxCss}
                                fullWidth
                                inputProps={{ "aria-label": "campaign description" }}
                            />
                        </div>
                        {errors?.description?.type === "pattern" && (<span style={ErrorStyle}>Special characters not allowed.</span>)}
                    </Grid>

                    <Grid item md={7} lg={7} xl={7} sm={12} xs={12}>
                        <Typography component="p" sx={{ mt: 1, mb: 2 }}>Communication Channel</Typography>
                            <RadioButtonGroup
                                key={channel}
                                className={['create-using-account-radio', channel === "email" ? "email" : "sms"].join(' ')}
                                value={channel}
                                labels={['SMS', 'Email']}
                                handleChange={handleContentChange}
                            />
                    </Grid>

                    {/* <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
                        <div>
                            <Typography className="form-ctrl-main-heading inline-blk" >Upload Data File (Required)</Typography>
                            <Grid item xs={7.1} sm={6.5} xl={7.1} lg={7.1} md={7.1}>
                                <ReactFileReader
                                    multipleFiles
                                    fileTypes={[".dat"]}
                                    handleFiles={handleFiles}
                                >
                                    <FileUpload selectedFile={selectedFile || campaignData.uploadedFile}
                                        showFileName
                                        fileType={false}
                                        file=".dat"
                                        // name="Upload"
                                        icon={<img alt="Upload icon" src={uploadIcon} />
                                        }
                                    />
                                </ReactFileReader>
                            </Grid>
                        </div>
                        {isError.showErrorMsg ? <div><p style={{ color: "red" }}>{isError.isErrorFile ? "Please upload valid file." : "Please upload a file."}</p></div> : null}
                    </Grid>
                    {selectedFile ? <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
                        <div className='mar-l-10 inline-blk'>
                            <p><b>{selectedFile ? selectedFile.name : ''}</b></p>
                        </div>
                        <div className='mar-l-10 inline-blk'>
                            <Button onClick={fileOpener} sx={{ color: appConfig.colors.darkblue, textTransform: 'capitalize', textDecoration: "underline", fontSize: appConfig.font16 }}>
                                Preview
                            </Button>
                        </div>

                    </Grid> : null} */}
                    <Grid item xs={12} sm={12} xl={12} lg={12} md={12}>
                        <hr className='blue-border' />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} xl={2} lg={2} md={2}>
                        <Styled.CancelButton
                            variant="outlined"
                            sx={{ mt: 3, ml: 1, fontFamily: appConfig.fontfamily, textTransform: 'capitalize', width: "100%", height: "50px" }}
                            color="primary"
                            onClick={() => {
                                navigate('/campaign')
                            }}
                        >
                            Cancel
                        </Styled.CancelButton>
                    </Grid>
                    <Grid item xs={12} sm={8} xl={5} lg={5} md={5}>
                        <Styled.CampaignButton
                            type='submit'
                            variant="contained"
                            sx={{ mt: 3, ml: 1, fontFamily: appConfig.fontfamily, textTransform: 'capitalize', width: "100%", height: "50px" }}
                            color="primary"
                            // onClick={() => handleFormSubmit()}
                            disabled={isError.isErrorFile}
                        >
                            Create
                        </Styled.CampaignButton>
                    </Grid>
                </Grid>
            </form>
    </div >
}

CreateCampaign.propTypes = {
    campaignData: PropTypes.arrayOf(PropTypes.array).isRequired,
    clearCampaignValues: PropTypes.func.isRequired,
    createNewCampaign: PropTypes.func.isRequired,
}


const mapDispatchToProps = (dispatch) => {
    return {
        setCampaignValues: (data) => {
            dispatch({ type: SET_CREATE_CAMPAIGN_VALUES, payload: data });
        },
        clearCampaignValues: () => {
            dispatch({ type: CLEAR_CREATE_CAMPAIGN_VALUES });
        },
        createNewCampaign: (data) => dispatch(campaignActions.createCampaign(data)),
    };
}
const mapStateToProps = (state) => ({ campaignData: state.createCampaign })


export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaign);