import * as actionTypes from './actionTypes';


export const changeMenu = (menu) => {
    return (dispatch) => {
        dispatch({
          type: actionTypes.MENU_CHANGE,
          payload:menu
        });   
      }
}


export const setSubmenu = (submenu) => {
  return (dispatch) => {
      dispatch({
        type: actionTypes.MENU_CHANGE,
        payload:submenu
      });   
    }
}
